import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useContext } from 'react';
import AppContext from '../../contexts/appContext/appContext';
import ISponsorImage from '../../interfaces/sponsorImage/sponsorImage';
import FacebookIcon from '../icons/socialMedia/facebook';
import InstagramIcon from '../icons/socialMedia/instagram';
import * as StyledMenu from './menu.styles';

interface Props {}

export const menuClosingAnimationDuration = 1000;
export const menuQuery = graphql`
	{
		prismicNavigation {
			data {
				items {
					item {
						document {
							uid
							data {
								title {
									text
								}
							}
						}
					}
				}
			}
		}
		allPrismicEvent(
			sort: { fields: data___order, order: ASC }
			filter: { data: { title: { text: { ne: "Ignored-dummy-event" } } } }
		) {
			edges {
				node {
					uid
					data {
						logo {
							url
						}
					}
				}
			}
		}
		prismicSettings {
			data {
				street_number
				zip_and_city
				country
				telephone
				email
				facebook_url {
					url
				}
				instagram_url {
					url
				}
			}
		}
		allPrismicSponsors {
			edges {
				node {
					data {
						sponsor_image_list {
							sponsor_image {
								url
							}
						}
					}
				}
			}
		}
	}
`;

const Menu: FC<Props> = () => {
	const menuAnimationDuration = 350;
	const delayGap = 100;
	const linkDelay = 0 - delayGap;
	const queryData = useStaticQuery(menuQuery);
	const mainLinks = queryData.prismicNavigation.data.items;
	const events = queryData.allPrismicEvent.edges;
	const contact = queryData.prismicSettings.data;
	const sponsorImageList: ISponsorImage[] = queryData.allPrismicSponsors.edges[0].node.data.sponsor_image_list;

	const { isMenuOpen, setIsMenuOpen, isMenuClosing, setIsMenuClosing } = useContext(AppContext);

	const onClick = () => {
		setIsMenuClosing(true);

		setTimeout(() => {
			setIsMenuOpen(false);
			setIsMenuClosing(false);
		}, menuClosingAnimationDuration);
	};

	return (
		<StyledMenu.Wrapper
			open={isMenuOpen}
			animationDuration={menuAnimationDuration}
			isClosing={isMenuClosing}
			menuClosingAnimationDuration={menuClosingAnimationDuration}>
			{isMenuOpen && <StyledMenu.NoScroll />}
			<StyledMenu.WrapperInner>
				<StyledMenu.Inner open={isMenuOpen}>
					<StyledMenu.Column>
						<StyledMenu.ColumnHeading>Menu</StyledMenu.ColumnHeading>
						<StyledMenu.MainLinks>
							{mainLinks.map((item, index) => {
								return (
									<StyledMenu.AnimationWrapper
										key={item.item.document[0].uid}
										delay={linkDelay + delayGap * index}>
										<StyledMenu.MainLink onClick={onClick} to={`/${item.item.document[0].uid}`}>
											{item.item.document[0].data.title.text}
										</StyledMenu.MainLink>
									</StyledMenu.AnimationWrapper>
								);
							})}
						</StyledMenu.MainLinks>
					</StyledMenu.Column>
					<StyledMenu.Column>
						<StyledMenu.ColumnHeading>Festivals</StyledMenu.ColumnHeading>
						<StyledMenu.Events>
							{events.map((item, index) => {
								return (
									<StyledMenu.AnimationWrapper
										key={item.node.uid}
										delay={linkDelay + delayGap * index}
										onClick={onClick}>
										<StyledMenu.EventLink to={`/event/${item.node.uid}`}>
											<StyledMenu.EventImage src={item.node.data.logo.url} alt={item.node.uid} />
										</StyledMenu.EventLink>
									</StyledMenu.AnimationWrapper>
								);
							})}
						</StyledMenu.Events>
					</StyledMenu.Column>
					<StyledMenu.Column>
						<StyledMenu.ColumnHeading>Contact</StyledMenu.ColumnHeading>
						<StyledMenu.Contact>
							<StyledMenu.AnimationWrapper delay={delayGap}>
								<StyledMenu.ContactLine>Platform GÁTT c/o</StyledMenu.ContactLine>
								<StyledMenu.ContactLine>Reykjavik Arts Festival</StyledMenu.ContactLine>
								<StyledMenu.ContactLine>{contact.street_number}</StyledMenu.ContactLine>
							</StyledMenu.AnimationWrapper>
							<StyledMenu.AnimationWrapper delay={delayGap * 2}>
								<StyledMenu.ContactLine>{contact.zip_and_city}</StyledMenu.ContactLine>
							</StyledMenu.AnimationWrapper>
							<StyledMenu.AnimationWrapper delay={delayGap * 3}>
								<StyledMenu.ContactLine>{contact.country}</StyledMenu.ContactLine>
							</StyledMenu.AnimationWrapper>
							<StyledMenu.AnimationWrapper delay={delayGap * 4}>
								<StyledMenu.ContactLine>{`Telephone: ${contact.telephone}`}</StyledMenu.ContactLine>
							</StyledMenu.AnimationWrapper>
							<StyledMenu.AnimationWrapper delay={delayGap * 5}>
								<StyledMenu.ContactLine>
									<a href={`mailto:${contact.email}`}>{contact.email}</a>
								</StyledMenu.ContactLine>
							</StyledMenu.AnimationWrapper>
						</StyledMenu.Contact>
					</StyledMenu.Column>
					<StyledMenu.Sponsors>
						{sponsorImageList.map((sponsor, index) => (
							<StyledMenu.AnimationWrapper delay={delayGap * (index + 1)} key={index}>
								<StyledMenu.Sponsor key={index}>
									<img src={sponsor.sponsor_image.url} alt={sponsor.sponsor_image.url} />
								</StyledMenu.Sponsor>
							</StyledMenu.AnimationWrapper>
						))}
					</StyledMenu.Sponsors>
				</StyledMenu.Inner>
			</StyledMenu.WrapperInner>
		</StyledMenu.Wrapper>
	);
};

export default Menu;
