import { createContext } from 'react';

const AppContext = createContext({
	isAnimationFinished: null,
	setIsAnimationFinished: null,
	isMenuOpen: null,
	setIsMenuOpen: null,
	isMenuClosing: null,
	setIsMenuClosing: null,
	artists: null,
	setArtists: null,
});

export default AppContext;
