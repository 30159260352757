import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

const defaultOptions = {
	throttle: 100
};

const getPosition = () => ({
	x: typeof window !== 'undefined' ? window.pageXOffset : 0,
	y: typeof window !== 'undefined' ? window.pageYOffset : 0,
});

export default function useWindowScrollPosition() {
	const [position, setPosition] = useState(getPosition());

	useEffect(() => {
		const handleScroll = throttle(() => {
			setPosition(getPosition());
		}, defaultOptions.throttle);

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return position;
}
