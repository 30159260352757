import React, { FC } from 'react';

interface Props {}

const FacebookIcon: FC<Props> = () => {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="30px"
			height="30px"
			viewBox="0 0 800.000000 800.000000"
			preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
				<path
					d="M3740 7594 c-899 -79 -1669 -433 -2285 -1049 -517 -517 -859 -1161
-994 -1875 -47 -249 -55 -352 -55 -670 0 -318 8 -421 55 -670 136 -714 477
-1358 994 -1875 505 -506 1148 -851 1830 -984 61 -12 120 -24 133 -27 l22 -4
0 1260 0 1260 -460 0 -460 0 0 520 0 520 459 0 460 0 4 528 c4 566 6 581 61
774 91 313 274 555 536 708 138 81 332 145 515 170 101 14 431 14 595 0 205
-18 445 -51 452 -64 4 -6 7 -204 7 -439 1 -329 -2 -429 -12 -432 -6 -2 -138
-6 -292 -8 -308 -5 -379 -16 -488 -74 -114 -61 -212 -198 -236 -330 -6 -28
-11 -226 -11 -440 l-2 -388 496 -3 c273 -1 496 -6 496 -10 0 -14 -149 -985
-155 -1009 l-5 -23 -415 -2 -415 -3 0 -1255 c0 -1001 2 -1255 12 -1253 88 13
264 51 383 84 973 266 1803 951 2256 1859 187 375 294 731 355 1175 26 185 26
685 0 870 -115 838 -456 1535 -1031 2110 -573 574 -1275 917 -2105 1031 -110
15 -594 27 -700 18z"
				/>
			</g>
		</svg>
	);
};

export default FacebookIcon;
