import styled from 'styled-components';
import media from '../../constants/media';
import zIndex from '../../constants/zIndex';

export const Wrapper = styled.div`
	position: fixed;
	z-index: ${zIndex.socialMediaLinks};
	padding: 0 2rem;

	${media.desktopSmall`
		padding: 0 3rem;
	`}

	${media.desktopLarge`
		padding: 0 10.15rem;
	`};
`;

export const Link = styled.a`
	display: block;
	g {
		transition: fill 100ms ease;
	}

	&:hover {
		g {
			fill: ${({ theme }) => theme.colors.primary};
		}
	}
`;
