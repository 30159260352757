import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useContext, useEffect, useState } from 'react';
import AppContext from '../../contexts/appContext/appContext';
import { menuClosingAnimationDuration } from '../menu/menu';
import * as StyledHeader from './header.styles';

interface Props {}

const query = graphql`
	{
		allPrismicEvent(
			sort: { fields: data___order, order: ASC }
			filter: { data: { title: { text: { ne: "Ignored-dummy-event" } } } }
		) {
			edges {
				node {
					id
					uid
					data {
						display_in_header
						title {
							text
						}
						logo {
							url
						}
					}
				}
			}
		}
	}
`;

const Header: FC<Props> = () => {
	const { isMenuOpen, setIsMenuOpen, setIsMenuClosing } = useContext(AppContext);
	const events = useStaticQuery(query).allPrismicEvent.edges;
	const [currentPage, setCurrentPage] = useState(undefined);
	useEffect(() => setCurrentPage(events.find(x => window.location.href.includes(x.node.uid))));
	const eventLogo = currentPage !== undefined ? currentPage.node.data.logo.url : null;
	const onLinkClick = () => {
		if (!isMenuOpen) return;

		setIsMenuClosing(true);

		setTimeout(() => {
			setIsMenuOpen(false);
			setIsMenuClosing(false);
		}, menuClosingAnimationDuration);
	};

	const onHamburgerClick = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<StyledHeader.Header>
			<StyledHeader.Left>
				<StyledHeader.LogoWrapperLink
					cover
					direction="up"
					duration={1}
					bg="#ffffff"
					to="/"
					onClick={onLinkClick}>
					{eventLogo === null || isMenuOpen ? (
						<StyledHeader.HeaderLogo />
					) : (
						<StyledHeader.EventLogo src={eventLogo} />
					)}
				</StyledHeader.LogoWrapperLink>
			</StyledHeader.Left>
			<StyledHeader.Right>
				{!isMenuOpen
					? events.map(event => {
							if (event.node.data.display_in_header) {
								const words = event.node.data.title.text.split(' ');
								return (
									<StyledHeader.EventLink
										cover
										direction="up"
										duration={1}
										bg="#ffffff"
										to={`/event/${event.node.uid}`}
										key={event.node.id}
										onClick={onLinkClick}>
										<span>
											{words.map(word => (
												<>
													{word}
													<br />
												</>
											))}
										</span>
									</StyledHeader.EventLink>
								);
							}

							return null;
					  })
					: null}
			</StyledHeader.Right>
			<StyledHeader.HamburgerClickWrapper onClick={onHamburgerClick}>
				<StyledHeader.Hamburger open={isMenuOpen} />
			</StyledHeader.HamburgerClickWrapper>
		</StyledHeader.Header>
	);
};

export default Header;
