const theme = {
	breakpoints: ['320px', '375px', '425px', '768px', '1024px', '1440px', '1920px'],
	colors: {
		primary: '#6A20CD',
		red: '#ED2541',
		borderColor: '#C9C9C9',
		gray: '#424242',
		textBase: '#5A5A5A',
		textLighter: '#7A7A7A',
	},
	fonts: {
		fontFamily: 'Adieu, Arial',
		fontFamilyHeadings: 'Adieu, Arial',
		fontSize: '1.6rem',
		fontSizeTablet: '1.8rem',
		fontSizeDesktop: '1.8rem',
		lineHeight: '2',
	},
	header: {
		height: '8rem',
		desktopHeight: '14rem',
	},
};

export default theme;
