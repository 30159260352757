
import styled, { keyframes, css } from 'styled-components';
import zIndex from '../../constants/zIndex';
import LogoDarkBlue from '../icons/logo/logoDarkBlue';
import LogoPurple from '../icons/logo/logoPurple';
import LogoGreen from '../icons/logo/logoGreen';
import LogoLightBlue from '../icons/logo/logoLightBlue';
import Logo from '../icons/logo';

export const entryMaskAnimation = keyframes`
	0% { transform: matrix(1, 0, 0, 1, 0, 0); }
	99% { transform: translate(0%, -100%) matrix(1, 0, 0, 1, 0, 0); }
	100% { transform: translate(0%, -100%) matrix(1, 0, 0, 1, 0, 0); opacity: 0; }
`;

export const logoInOut = keyframes`
	0% { transform: scale(0.95); opacity: 0; }
	50% { transform: scale(1); opacity: 1; }
	100% { opacity: 0; }
`;

export const Wrapper = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	z-index: ${zIndex.entryMask};
	background-color: #f0f0f0;
	transition: opacity 250ms ease;
	pointer-events: none;
	animation: ${entryMaskAnimation} ${({ duration }) => duration}s linear forwards;
	animation-delay: ${({ delay }) => delay}s;
`;

const duration = 1000;
const gap = 475;
const darkBlue = 400;
const purple = darkBlue + gap;
const green = purple + gap;
const lightBlue = green + gap;
const defaultLogo = lightBlue + gap;

const basicLogoStyles = css`
	position: absolute;
	transform-origin: center;
	max-width: 20rem;
	opacity: 0;
	animation: ${logoInOut} ${duration}ms ease forwards;
`;

export const StyledLogoDarkBlue = styled(LogoDarkBlue)`
	${basicLogoStyles};
	animation-delay: ${darkBlue}ms;
`;

export const StyledLogoPurple = styled(LogoPurple)`
	${basicLogoStyles};
	animation-delay: ${purple}ms;
`;

export const StyledLogoGreen = styled(LogoGreen)`
	${basicLogoStyles};
	animation-delay: ${green}ms;
`;

export const StyledLogoLightBlue = styled(LogoLightBlue)`
	${basicLogoStyles};
	animation-delay: ${lightBlue}ms;
`;

export const StyledLogo = styled(Logo)`
	${basicLogoStyles};
	animation-delay: ${defaultLogo}ms;
	margin-left: 3px;
	margin-top: 2px;
`;
