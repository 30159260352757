import styled, { css, createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';
import zIndex from '../../constants/zIndex';
import media from '../../constants/media';
import { moveUp } from '../../constants/keyframes/movements';

export const Wrapper = styled.div`
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overflow-y: auto;
	background-color: #fff;
	z-index: ${zIndex.menu};
	opacity: 0;
	transform: translate3d(0, 100%, 0);
	pointer-events: none;
	transition: none;

	${({ open }) => open && css`
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: opacity 250ms ease, transform ${({ animationDuration }) => animationDuration}ms ease;
		pointer-events: all;
	`}

	${({ isClosing }) => isClosing && css`
		transform: translate3d(0, 100%, 0);
		transition: transform ${({ menuClosingAnimationDuration }) => menuClosingAnimationDuration}ms ease;
	`};
`;

export const NoScroll = createGlobalStyle`
	body {
		overflow: hidden;
	}
`;

export const WrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Inner = styled.div`
	display: ${({ open }) => (open ? 'flex' : 'none')};
	flex-wrap: wrap;
	width: 100%;
	margin: auto;
	padding: 12rem 2rem 2rem 2rem;

	${media.mobileOnly`
		display: ${({ open }) => (open ? 'block' : 'none')};
		max-height: 100%;
		overflow-y: scroll;
	`}

	${media.desktopSmall`
		padding: 24rem 6rem 6rem 16rem;
	`}

	${media.desktopLarge`
		padding: 24rem 12rem 12rem 16rem;
	`}

	${media.desktopHuge`
		padding: 24rem 0 12rem 0;
		max-width: 180rem;
	`}
`;

export const Column = styled.div`
	width: 33%;
	flex: 0 0 auto;

	${media.mobileOnly`
		width: 100%;
		margin-bottom: 8rem;
	`}
`;

export const ColumnHeading = styled.div`
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 3rem;
	color: #000;
	text-transform: uppercase;
`;

export const MainLinks = styled.div`
	display: flex;
	flex-direction: column;
`;

export const AnimationWrapper = styled.div`
	overflow: hidden;

	> * {
		display: block;
		transform: translate3d(0, 100%, 0);
		animation: ${moveUp} 1000ms ease forwards;
		animation-delay: ${({ delay }) => delay}ms;
	}
`;

export const MainLink = styled(Link)`
	font-size: 3rem;
	color: #000;
	font-weight: 700;
	transition: color 150ms ease;
	text-transform: uppercase;

	&:hover {
		color: ${({ theme }) => theme.colors.textLighter};
	}

	${media.desktopHuge`
		font-size: 5.5rem;
	`}
`;

export const Events = styled.div`
	display: flex;
	flex-wrap: wrap;

	${AnimationWrapper} {
		width: 50%;
		flex: 0 0 auto;
	}
`;

export const EventLink = styled(Link)`
	padding-right: 30%;
`;

export const EventImage = styled.img`
`;

export const Contact = styled.div`
	margin-bottom: 6rem;
`;

export const ContactLine = styled.div`
	font-weight: 500;
	margin-bottom: 0.5rem;
`;

export const SocialMedia = styled.div`
	margin: 0 -1.5rem;
`;

export const SocialMediaLink = styled.a`
	padding: 1.5rem;
`;

export const Sponsors = styled.div`
	width: 100%;

	${media.tablet`
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex: 0 0 100%;
		margin-top: 6rem;
	`}

`;

export const Sponsor = styled.div`
	padding: 2rem;
	text-align: center;

	${media.tablet`
		min-width: 33%;
	`}

	${media.desktopSmall`
		min-width: auto;
	`}

	img {
		max-width: 24rem;
		max-height: 4rem;
	}
`;
