import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as StyledSocialMedia from './socialMedia.styles';
import FacebookIcon from '../icons/socialMedia/facebook';
import InstagramIcon from '../icons/socialMedia/instagram';

interface Props {}

const query = graphql`
	{
		prismicSettings {
			data {
				facebook_url {
					url
					target
				}
				instagram_url {
					url
					target
				}
			}
		}
	}
`;

const SocialMedia: FC<Props> = () => {
	const { data } = useStaticQuery(query).prismicSettings;

	return (
		<StyledSocialMedia.Wrapper>
			<StyledSocialMedia.Link href={data.facebook_url.url} target={data.facebook_url.target}>
				<FacebookIcon />
			</StyledSocialMedia.Link>
			<StyledSocialMedia.Link href={data.instagram_url.url} target={data.instagram_url.target}>
				<InstagramIcon />
			</StyledSocialMedia.Link>
		</StyledSocialMedia.Wrapper>
	);
};

export default SocialMedia;
