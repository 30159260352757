import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface Props {}

const Head: FC<Props> = () => {
	return (
		<Helmet>
			<title>Gátt</title>
			<link href="/css/fonts.css" type="text/css" rel="stylesheet" />
		</Helmet>
	);
};

export default Head;
