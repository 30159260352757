import React, { FC } from 'react';

interface Props {}

const InstagramIcon: FC<Props> = () => {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="30px"
			height="30px"
			viewBox="0 0 800.000000 800.000000"
			preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
				<path
					d="M3740 7594 c-386 -34 -727 -112 -1055 -241 -830 -327 -1510 -950
-1907 -1745 -186 -374 -293 -727 -354 -1173 -26 -185 -26 -685 0 -870 115
-838 456 -1535 1031 -2110 517 -517 1161 -859 1875 -994 249 -47 352 -55 670
-55 318 0 421 8 670 55 1103 209 2051 926 2551 1929 187 375 294 731 355 1175
26 185 26 685 0 870 -115 838 -456 1535 -1031 2110 -573 574 -1275 917 -2105
1031 -110 15 -594 27 -700 18z m1355 -1501 c499 -116 854 -458 981 -948 l29
-110 0 -1020 c0 -932 -2 -1027 -17 -1101 -27 -123 -54 -199 -113 -319 -173
-351 -488 -591 -895 -682 -66 -14 -184 -17 -1005 -20 -628 -3 -965 -1 -1036 6
-564 58 -1006 457 -1127 1015 -15 74 -17 169 -17 1101 l0 1020 28 106 c44 171
107 310 195 434 217 306 555 502 927 538 22 2 472 3 1000 2 935 -1 962 -2
1050 -22z"
				/>
				<path
					d="M3035 5709 c-371 -54 -678 -357 -735 -725 -7 -46 -10 -380 -8 -1024
l3 -955 23 -74 c80 -260 266 -470 505 -569 168 -70 107 -67 1177 -67 887 0
971 1 1033 18 172 44 318 128 435 251 104 109 169 222 214 369 23 72 23 73 26
1032 2 671 0 980 -8 1025 -32 184 -110 332 -249 470 -143 142 -305 224 -494
249 -95 13 -1832 13 -1922 0z m2190 -373 c89 -41 138 -119 138 -221 0 -101
-51 -183 -140 -225 -195 -91 -404 94 -341 302 15 51 82 126 133 149 58 27 147
25 210 -5z m-1101 -297 c453 -48 811 -371 913 -824 20 -89 22 -306 4 -400 -97
-496 -523 -849 -1021 -848 -298 1 -545 106 -752 321 -192 199 -288 438 -288
717 0 279 96 518 288 717 187 194 394 292 672 320 66 7 92 7 184 -3z"
				/>
				<path
					d="M3885 4656 c-428 -98 -652 -551 -465 -937 81 -167 212 -282 395 -345
70 -25 95 -28 200 -28 131 0 195 14 295 64 221 111 356 318 368 565 7 165 -41
314 -145 446 -77 98 -207 185 -333 223 -76 23 -240 29 -315 12z"
				/>
			</g>
		</svg>
	);
};

export default InstagramIcon;
