import React, { FC } from 'react';

interface Props {

}

const Logo: FC<Props> = (props) => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			x="0px"
			y="0px"
			width="415.5px"
			height="420.7px"
			viewBox="0 0 415.5 420.7"
			{...props}>
			<g id="XMLID_33_">
				<path
					id="XMLID_46_"
					d="M394.2,206.2c0-106.4-86.2-192.6-192.6-192.6C95.2,13.5,9,99.8,9,206.2s86.2,192.6,192.6,192.6
							C308,398.8,394.2,312.5,394.2,206.2" />
			</g>
			<rect id="XMLID_32_" x="66.4" y="71" className="st0" fill="#fff" width="270.4" height="270.4" />
			<g id="XMLID_30_">
				<path
					id="XMLID_31_"
					d="M336.2,206.2c0-74.3-60.3-134.6-134.6-134.6C127.2,71.5,67,131.8,67,206.2s60.3,134.6,134.6,134.6
							C275.9,340.8,336.2,280.5,336.2,206.2" />
			</g>
			<rect id="XMLID_29_" x="106.2" y="110.8" className="st0" fill="#fff" width="190.7" height="190.7" />
			<g id="XMLID_27_">
				<path
					id="XMLID_28_"
					d="M296.4,206.2c0-52.4-42.4-94.8-94.8-94.8s-94.8,42.4-94.8,94.8s42.4,94.8,94.8,94.8
							S296.4,258.5,296.4,206.2" />
			</g>
			<rect id="XMLID_26_" x="134.5" y="139" className="st0" fill="#fff" width="134.2" height="134.2" />
			<g id="XMLID_23_">
				<path
					id="XMLID_25_"
					d="M267.6,206.2c0-36.5-29.6-66.1-66.1-66.1c-36.5,0-66.1,29.6-66.1,66.1s29.6,66.1,66.1,66.1
							C238.1,272.2,267.6,242.6,267.6,206.2" />
				<path
					id="XMLID_24_"
					className="st0"
fill="#fff"
					d="M199.6,187.4c-2.1,0.6-4,1.2-5.9,1.7c-0.2,0-0.4,0-0.6-0.1c-5.3-3.3-11.1-4-17.2-3.3
							c-3.1,0.3-6,1.1-8.7,2.9c-0.4,0.2-0.7,0.5-1,0.8c-3,2.5-3,6.5,0,9c2.3,2,5.1,2.9,8,3.4c4.7,0.8,9.4,0.7,14-0.6
							c2.1-0.5,4-1.4,5.7-2.7c0.4-0.4,0.9-0.7,1.3-1.1c-0.1-0.1-0.1-0.2-0.2-0.3h-17.1c0-1.5,0-2.9,0-4.3c0-0.1,0.3-0.3,0.4-0.4
							c0.1-0.1,0.3,0,0.5,0c6.9,0,13.7,0,20.6,0c0.7,0,1,0.2,1,1c-0.1,1.8,0,3.6,0,5.4c0,0.5-0.2,1.1-0.5,1.5c-1.4,1.7-3.1,2.9-5,3.8
							c-3.3,1.6-6.8,2.4-10.4,2.8c-4.6,0.5-9.2,0.3-13.7-0.8c-3.3-0.8-6.3-2-8.9-4.2c-5.2-4.3-5.2-11.5,0-15.8c3.3-2.7,7.1-4,11.2-4.7
							c4.5-0.8,9-0.8,13.4,0c4.6,0.8,8.8,2.4,12.4,5.3C198.9,186.7,199.2,187,199.6,187.4" />
			</g>
			<polygon
				id="XMLID_22_"
				className="st0"
fill="#fff"
				points="226.5,211.7 212.9,211.7 202.3,211.7 202.3,216.5 212.9,216.5 220.9,216.5 220.9,236.1
					226.5,236.1 226.5,216.5 245.7,216.5 245.7,211.7 " />
			<g id="XMLID_18_">
				<path
					id="XMLID_19_"
					className="st0"
fill="#fff"
					d="M223.5,187.2c-2.5,3.1-4.9,6.1-7.4,9.2h14.7C228.4,193.3,226,190.3,223.5,187.2 M201.6,206
							c0.7-0.9,1.4-1.7,2-2.5c5.7-7.1,11.4-14.3,17-21.4c0.3-0.4,0.6-0.6,1.2-0.6c1.2,0,2.5,0,3.7,0c0.4,0,0.8,0.2,1,0.5
							c6.2,7.8,12.4,15.6,18.6,23.4c0.1,0.1,0.2,0.3,0.4,0.6c-0.3,0-0.5,0.1-0.7,0.1c-1.9,0-3.7,0-5.6,0c-0.3,0-0.8-0.2-1-0.5
							c-1-1.2-2-2.5-3-3.7c-0.2-0.2-0.6-0.5-1-0.5c-7.1,0-14.3,0-21.4,0c-0.3,0-0.7,0.2-0.9,0.5c-1,1.2-2,2.5-3,3.7
							c-0.2,0.2-0.5,0.5-0.8,0.5C206,206,203.9,206,201.6,206" />
			</g>
			<polygon id="XMLID_17_" className="st0" fill="#fff" points="221.7,177.4 227.2,167.8 232.6,167.8 227.6,177.4 " />
			<polygon
				id="XMLID_16_"
				className="st0"
fill="#fff"
				points="197.8,211.7 182.1,211.7 165.3,211.7 157.5,211.7 157.5,216.5 165.3,216.5 176.5,216.5
					176.5,236.1 182.1,236.1 182.1,216.5 197.8,216.5 " />
		</svg>

	);
};

export default Logo;
