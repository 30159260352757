import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../../constants/theme';
import GlobalStyle from '../../constants/globalStyle';
import EntryMask from '../entryMask';
import Header from '../header/header';
import Head from '../head';
import AppContextProvider from '../../contexts/appContext/appContextProvider';
import Menu from '../menu/menu';
import SocialMedia from '../socialMedia';

interface Props {}

const Layout: FC<Props> = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<AppContextProvider>
				<>
					<Head />
					<GlobalStyle />
					<Header />
					<SocialMedia />
					<Menu />
					<EntryMask />
					{children}
				</>
			</AppContextProvider>
		</ThemeProvider>
	);
};

export default Layout;
