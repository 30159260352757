import { css } from 'styled-components';

export const containerBreakpoint = 1620;
export const pageMaxWidth = 10300;

interface Media {
	mobileOnly: any,
	mobileMedium: any,
	mobileMediumOnly: any,
	mobileLarge: any,
	mobileLargeOnly: any,
	tablet: any,
	tabletOnly: any,
	desktopSmall: any,
	desktopSmallOnly: any,
	desktopLarge: any,
	desktopLargeOnly: any,
	desktopHuge: any,
	containerBreakpoint: any,
	pageMaxWidth: any,
	retina: any
}

const sizes = {
	mobileOnly: [0, 767],
	mobileMedium: 375,
	mobileMediumOnly: [375, 424],
	mobileLarge: 425,
	mobileLargeOnly: [425, 767],
	tablet: 768,
	tabletOnly: [768, 1023],
	desktopSmall: 1024,
	desktopSmallOnly: [1024, 1439],
	desktopLarge: 1440,
	desktopLargeOnly: [1440, 1920],
	desktopHuge: 1920,
	containerBreakpoint: containerBreakpoint,
	pageMaxWidth: pageMaxWidth,
	retina: 2560
};

// Iterate through the sizes and create a media template
export const media:Media = Object.keys(sizes).reduce((accumulator: any, label: string) => {
	if(Array.isArray(sizes[label])) {
		accumulator[label] = (...args) => css`
			@media (min-width: ${sizes[label][0]}px) and (max-width: ${sizes[label][1]}px) {
				${css(...args)};
			}
		`;
	} else {
		accumulator[label] = (...args) => css`
			@media (min-width: ${sizes[label]}px) {
				${css(...args)};
			}
		`;
	}
	return accumulator;
}, {});
