import React, { FC, useContext, useState } from 'react';
import * as StyledEntryMask from './entryMask.styles';
import AppContext from '../../contexts/appContext/appContext';
import useWindowScrollPosition from '../../hooks/useWindowScrollPosition';

interface Props {}

const duration = 0.3;

const videoLarge = require('./largeIntro.mp4');
const videoMedium = require('./mediumIntro.mp4');
const videoSmall = require('./smallIntro.mp4');
const videoMicro = require('./microIntro.mp4');

const EntryMask: FC<Props> = () => {
	const [delay, setDelay] = useState(6);
	const position = useWindowScrollPosition();
	const { isAnimationFinished, setIsAnimationFinished } = useContext(AppContext);

	if (position.y > 0 && delay > 0 && !isAnimationFinished) {
		setDelay(0);
		setIsAnimationFinished(true);
	}

	// Waits for the animation to finish and then updates the context to let everyone know
	setTimeout(() => {
		if (!isAnimationFinished) setIsAnimationFinished(true);
	}, (duration + delay) * 1000);

	return (
		<StyledEntryMask.Wrapper delay={delay} duration={duration}>
			<video autoPlay muted loop id="intro">
				<source src={videoSmall} type="video/mp4" />
			</video>
		</StyledEntryMask.Wrapper>
	);
};

/**
 *  this goes instead of video in case the customer wants old function back
			<StyledEntryMask.StyledLogo />
			<StyledEntryMask.StyledLogoDarkBlue />
			<StyledEntryMask.StyledLogoPurple />
			<StyledEntryMask.StyledLogoGreen />
			<StyledEntryMask.StyledLogoLightBlue />

			and change usestate(3) for duration.
 */

export default EntryMask;
