import React, { FC, useState } from 'react';
import AppContext from './appContext';

interface Props {}

const AppContextProvider: FC<Props> = ({ children }) => {
	const [isAnimationFinished, setIsAnimationFinished] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isMenuClosing, setIsMenuClosing] = useState(false);
	const [artists, setArtists] = useState(null);

	const context = {
		isAnimationFinished,
		setIsAnimationFinished,
		isMenuOpen,
		setIsMenuOpen,
		isMenuClosing,
		setIsMenuClosing,
		artists,
		setArtists,
	};

	return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
