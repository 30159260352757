import React, { FC } from 'react';

interface Props {

}

const LogoPurple: FC<Props> = (props) => {
	return (
		<svg version="1.1" id="Layer_1" x="0px" y="0px" width="415.5px" height="420.7px" viewBox="0 0 415.5 420.7" {...props}>
			<g id="XMLID_33_">
				<path id="XMLID_46_" fill="#654A9E" d="M403.3,211.8c0-106.4-86.2-192.6-192.6-192.6c-106.4,0-192.6,86.2-192.6,192.6 s86.2,192.6,192.6,192.6C317,404.4,403.3,318.1,403.3,211.8" />
			</g>
			<rect id="XMLID_32_" x="75.5" y="76.6" fill="#A087BF" width="270.4" height="270.4" />
			<g id="XMLID_30_">
				<path
id="XMLID_31_"
fill="#654A9E"
d="M345.3,211.8c0-74.3-60.3-134.6-134.6-134.6c-74.3,0-134.6,60.3-134.6,134.6
				s60.3,134.6,134.6,134.6C285,346.4,345.3,286.1,345.3,211.8" />
			</g>
			<rect id="XMLID_29_" x="115.3" y="116.4" fill="#A087BF" width="190.7" height="190.7" />
			<g id="XMLID_27_">
				<path
id="XMLID_28_"
fill="#654A9E"
d="M305.5,211.8c0-52.4-42.4-94.8-94.8-94.8s-94.8,42.4-94.8,94.8s42.4,94.8,94.8,94.8
				S305.5,264.1,305.5,211.8" />
			</g>
			<rect id="XMLID_26_" x="143.6" y="144.6" fill="#A087BF" width="134.2" height="134.2" />
			<g id="XMLID_23_">
				<path
id="XMLID_25_"
fill="#654A9E"
d="M276.7,211.8c0-36.5-29.6-66.1-66.1-66.1c-36.5,0-66.1,29.6-66.1,66.1s29.6,66.1,66.1,66.1
				C247.2,277.8,276.7,248.2,276.7,211.8" />
				<path
id="XMLID_24_"
fill="#FFFFFF"
d="M208.7,193c-2.1,0.6-4,1.2-5.9,1.7c-0.2,0-0.4,0-0.6-0.1c-5.3-3.3-11.1-4-17.2-3.3
				c-3.1,0.3-6,1.1-8.7,2.9c-0.4,0.2-0.7,0.5-1,0.8c-3,2.5-3,6.5,0,9c2.3,2,5.1,2.9,8,3.4c4.7,0.8,9.4,0.7,14-0.6
				c2.1-0.5,4-1.4,5.7-2.7c0.4-0.4,0.9-0.7,1.3-1.1c-0.1-0.1-0.1-0.2-0.2-0.3h-17.1c0-1.5,0-2.9,0-4.3c0-0.1,0.3-0.3,0.4-0.4
				c0.1-0.1,0.3,0,0.5,0c6.9,0,13.7,0,20.6,0c0.7,0,1,0.2,1,1c-0.1,1.8,0,3.6,0,5.4c0,0.5-0.2,1.1-0.5,1.5c-1.4,1.7-3.1,2.9-5,3.8
				c-3.3,1.6-6.8,2.4-10.4,2.8c-4.6,0.5-9.2,0.3-13.7-0.8c-3.3-0.8-6.3-2-8.9-4.2c-5.2-4.3-5.2-11.5,0-15.8c3.3-2.7,7.1-4,11.2-4.7
				c4.5-0.8,9-0.8,13.4,0c4.6,0.8,8.8,2.4,12.4,5.3C208,192.3,208.3,192.6,208.7,193" />
			</g>
			<polygon
id="XMLID_22_"
fill="#FFFFFF"
points="235.6,217.3 221.9,217.3 211.3,217.3 211.3,222.1 221.9,222.1 230,222.1 230,241.7
			235.6,241.7 235.6,222.1 254.8,222.1 254.8,217.3 " />
			<g id="XMLID_18_">
				<path
id="XMLID_19_"
fill="#FFFFFF"
d="M232.6,192.8c-2.5,3.1-4.9,6.1-7.4,9.2H240C237.5,198.9,235.1,195.9,232.6,192.8 M210.7,211.6
				c0.7-0.9,1.4-1.7,2-2.5c5.7-7.1,11.4-14.3,17-21.4c0.3-0.4,0.6-0.6,1.2-0.6c1.2,0,2.5,0,3.7,0c0.4,0,0.8,0.2,1,0.5
				c6.2,7.8,12.4,15.6,18.6,23.4c0.1,0.1,0.2,0.3,0.4,0.6c-0.3,0-0.5,0.1-0.7,0.1c-1.9,0-3.7,0-5.6,0c-0.3,0-0.8-0.2-1-0.5
				c-1-1.2-2-2.5-3-3.7c-0.2-0.2-0.6-0.5-1-0.5c-7.1,0-14.3,0-21.4,0c-0.3,0-0.7,0.2-0.9,0.5c-1,1.2-2,2.5-3,3.7
				c-0.2,0.2-0.5,0.5-0.8,0.5C215.1,211.6,213,211.6,210.7,211.6" />
			</g>
			<polygon id="XMLID_17_" fill="#FFFFFF" points="230.8,183 236.2,173.4 241.7,173.4 236.7,183 " />
			<polygon
id="XMLID_16_"
fill="#FFFFFF"
points="206.9,217.3 191.2,217.3 174.3,217.3 166.6,217.3 166.6,222.1 174.3,222.1 185.6,222.1
			185.6,241.7 191.2,241.7 191.2,222.1 206.9,222.1 " />
		</svg>

	);
};

export default LogoPurple;
