import { createGlobalStyle } from 'styled-components';
import media from '../media';

const GlobalStyle = createGlobalStyle`
	* {
		border: 0;
		box-sizing: inherit;
		-webkit-font-smoothing: antialiased;
		font-weight: inherit;
		margin: 0;
		outline: 0;
		padding: 0;
		text-decoration: none;
		text-rendering: optimizeLegibility;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	html {
		font-size: 62.5%;
	}

	html, body {
		min-height: 100%;
	}

	strong {
		font-weight: 500;
		color: ${({ theme }) => theme.colors.black};
	}

	img, picture {
		max-width: 100%;
	}

	body {
		max-width: 100vw;
		font-size: ${({ theme }) => theme.fonts.fontSize};
		font-family: ${({ theme }) => theme.fonts.fontFamily};
		font-weight: 300;
		line-height: ${({ theme }) => theme.fonts.lineHeight};
		color: ${({ theme }) => theme.colors.textBase};
		box-sizing: border-box;
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		padding-top: ${({ theme }) => theme.header.height};

		${media.tablet`
			font-size: ${({ theme }) => theme.fonts.fontSizeTablet};
		`}

		${media.desktopSmall`
			font-size: ${({ theme }) => theme.fonts.fontSizeDesktop};
			padding-top: ${({ theme }) => theme.header.desktopHeight};
		`}

		> div {
			overflow-x: hidden;
		}
	}

	a {
		color: #00A1D8;
	}
`;

export default GlobalStyle;
