import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled, { css } from 'styled-components';
import Logo from '../../components/icons/logo';
import media from '../../constants/media';
import zIndex from '../../constants/zIndex';

export const Header = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	background-color: #fff;
	height: ${({ theme }) => theme.header.height};
	width: 100%;
	z-index: ${zIndex.header};

	${media.desktopSmall`
		height: ${({ theme }) => theme.header.desktopHeight};
	`}

	${media.desktopLarge`
		padding: 0 3rem;
	`};
`;

export const LogoWrapperLink = styled(AniLink)`
	display: flex;
	align-items: center;
	height: ${({ theme }) => theme.header.height};
	transition: opacity 250ms ease 200ms;
	padding: 1rem;

	${media.desktopLarge`
		height: ${({ theme }) => theme.header.desktopHeight};
		padding: 2rem 4rem;
	`}
`;

export const HeaderLogo = styled(Logo)`
	max-height: 100%;
	width: auto;
`;

export const EventLogo = styled.img`
	max-height: 100%;
	width: auto;
`;

export const Left = styled.div`
	display: flex;
	align-items: center;
`;

export const Right = styled.div`
	display: none;

	${media.desktopSmall`
		display: flex;
		align-items: stretch;
	`}

	${media.desktopHuge`
		padding-right: 10vw;
	`}
`;

export const HamburgerClickWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 6.8rem;
	padding: 0 2rem;
	height: 8rem;
	margin-left: auto;
	margin-right: 0;
	cursor: pointer;

	${media.desktopSmall`
		width: 10.8rem;
		padding: 0 4rem;
		padding-top: 5rem;
	`};

	${media.desktopLarge`
		margin-right: 6rem;
	`}
`;

export const Hamburger = styled.div`
	display: flex;
	flex: 0 0 2.8rem;
	width: 2.8rem;
	height: 1.5rem;
	flex-direction: column;
	justify-content: space-between;
	transition: justify-content 250ms ease;

	&::before,
	&::after {
		content: '';
		height: 4px;
		width: 100%;
		background-color: ${({ theme }) => theme.colors.gray};
		transform-origin: center;
		transition: transform 250ms ease, background-color 250ms ease;
	}

	${({ open }) =>
		open &&
		css`
			justify-content: center;

			&::before {
				transform: rotate(45deg) translateY(3px);
			}

			&::after {
				transform: rotate(-45deg) translateY(-2px);
			}
		`}
`;

export const EventLink = styled(AniLink)`
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 900;
	color: #000;
	text-transform: uppercase;

	> span {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		height: 5.3rem;
		padding: 0 3rem;
		transition: color 200ms ease;
		overflow: hidden;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #111;
			transform: translate3d(0, -101%, 0);
			transition: transform 200ms ease, width 200ms ease 200ms;
		}
	}

	&:hover {
		> span {
			color: #fff;

			&:after {
				transform: translate3d(0, 0, 0);
				transition: transform 200ms ease, width 200ms ease 100ms;
				z-index: -1;
			}
		}
	}
`;
