// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-artist-tsx": () => import("/vercel/path0/src/templates/artist.tsx" /* webpackChunkName: "component---src-templates-artist-tsx" */),
  "component---src-templates-event-tsx": () => import("/vercel/path0/src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-info-page-tsx": () => import("/vercel/path0/src/templates/infoPage.tsx" /* webpackChunkName: "component---src-templates-info-page-tsx" */),
  "component---src-templates-press-page-tsx": () => import("/vercel/path0/src/templates/pressPage.tsx" /* webpackChunkName: "component---src-templates-press-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/vercel/path0/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/vercel/path0/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path0/.cache/data.json")

